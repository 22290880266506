import * as React from "react"
import '../style/style.css';
import Layout from '../components/Layout';
import MainBanner from '../components/products/MainBanner';
import Ingredients from "../components/products/Ingredients";
import Benefits from '../components/products/Benefits';
import Varieties from '../components/products/Varieties';
import { graphql,useStaticQuery } from 'gatsby';
import { getImage, withArtDirection } from "gatsby-plugin-image";


const PeAdMyPPage = () => {
    const data = useStaticQuery(graphql`
      query{
        file(relativePath: {eq: "Productos/Fondos/PerrosAdultosMyP.jpg"}) {
          childImageSharp {
            gatsbyImageData
            id
          }
        }

        mobileHeader: file(relativePath: {eq: "Productos/Mobile/PerrosAdultosMyP.jpg"}) {
          childImageSharp {
            gatsbyImageData
            id
          }
        }

        allFile(
          filter: {relativeDirectory: {eq: "Ingredientes/PC"}}
          sort: {fields: base}
        ) {
          nodes {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
              )
              id
            }
          }
        }

        transicion: file(relativePath: {eq: "Transicion/pamyp.png"}) {
          childImageSharp {
            gatsbyImageData
          }
        }

        site {
          siteMetadata {
            productosCarousel {
              id
              button
              color
              description
              historyButton
              igText
              knowText
              presentation
              subtitle
              title
              igUrl
            }
            ingredientes {
              adultosmyp {
                title
                description
                color
                id
              }
            }
            nutritionTable {
              adultosmyp {
                energy
                ingredients
                values {
                  factorEn
                  factorEs
                  max
                  min
                }
              }
            }
            benefitsTitle {
              adultosmyp
            }
            benefits {
              adultosmyp {
                description
                title
              }
            }
            varieties {
              adultosmyp {
                color
                description
                href
                id
                kgs {
                  kg
                }
                subtitle
                title
              }
            }
          }
        }

        benefitsBackground: file(relativePath: {eq: "Beneficios/Fondos/PAMYP.jpg"}) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 2010) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        benefitsBackgroundMobile: file(relativePath: {eq: "Beneficios/FondosMobile/PAMYP.jpg"}) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        dogPhoto: file(relativePath: {eq: "Mascotas/PAMYP.png"}) {
          childImageSharp {
            gatsbyImageData
          }
        }

        itemsIcons: allFile(
          filter: {relativeDirectory: {eq: "Beneficios/Items/PAMYP"}}
          sort: {order: ASC, fields: base}
        ) {
          nodes {
            childImageSharp {
              gatsbyImageData
            }
          }
        }

        varietiesImgs: allFile(filter: {relativeDirectory: {eq: "Variedades/AdultosMyP"}}
        sort: {fields: base, order: ASC}) {
          nodes {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
              )
            }
          }
        }

      }
    `);

    const imgMainBanner = withArtDirection(getImage(data.file.childImageSharp.gatsbyImageData),[
      {
        media: '(max-width: 420px)',
        image: getImage(data.mobileHeader.childImageSharp.gatsbyImageData),
      },
    ]);

    const pageInfo = data.site.siteMetadata.productosCarousel[3];

    const ingredientes = data.site.siteMetadata.ingredientes[0].adultosmyp;

    const images = data.allFile.nodes;

    const transicion = data.transicion.childImageSharp.gatsbyImageData;

    const nutritionTable = data.site.siteMetadata.nutritionTable[0].adultosmyp;

    const benefitsBackground = [
      data.benefitsBackground.childImageSharp.fluid,
    {
        ...data.benefitsBackgroundMobile.childImageSharp.fluid,
        media: `(max-width: 420px)`
    },
  ]

    const dogPhoto = data.dogPhoto.childImageSharp.gatsbyImageData;

    const itemsIcons = data.itemsIcons.nodes;

    const benefitTitle = data.site.siteMetadata.benefitsTitle.adultosmyp;

    const benefits = data.site.siteMetadata.benefits[0].adultosmyp;

    const varieties = data.site.siteMetadata.varieties[0].adultosmyp;

    const varietiesImg = data.varietiesImgs.nodes;

    return (
    <main>
    <title>Livra | Perros Adultos (Minis y Pequeños)</title>
    <Layout>
        <MainBanner className={'perros-adultos-img'} img={imgMainBanner}  info={pageInfo}/>
        <Ingredients ingredientes={ingredientes} images={images} transicion={transicion} nutritionTable={nutritionTable} tipo={'Pamyp'}/>
        <Benefits benefitText={benefitTitle} background={benefitsBackground} dogPhoto={dogPhoto} itemsIcons={itemsIcons} benefits={benefits}/>
        <Varieties data={varieties} images={varietiesImg} />
    </Layout>
  </main>
  );
}

export default PeAdMyPPage;

export { Head } from "../components/Head";